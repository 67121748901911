<template>
  <div>
    <v-select
      v-model="selected_career_id"
      :items="careerList"
      item-value="id"
      item-text="name"
      dense
      hide-details
      :label="`${$getVisibleNames('mesh.career', true, 'Programas')}`"
      class="mt-3 mb-1"
    ></v-select>
    <v-select
      v-if="egressProfileList.length > 1"
      v-model="selected_egress_profile_id"
      :items="egressProfileList"
      item-value="id"
      item-text="academic_degree"
      dense
      hide-details
      :label="
        $getVisibleNames('mesh.egressprofile', true, 'Perfiles De Egreso')
      "
      class="mt-4 mb-1"
    ></v-select>
    <v-select
      v-model="selected_campus_id"
      :items="filteredCampuses"
      item-value="id"
      item-text="name"
      dense
      hide-details
      :label="$getVisibleNames('mesh.campus', false, 'Sede')"
      class="mt-3 mb-1"
    ></v-select>
    <v-select
      v-if="egressProfile"
      v-model="selected_level"
      :items="levels"
      dense
      hide-details
      label="Nivel"
      class="mt-4 mb-2"
    ></v-select>
    <v-select
      v-if="egressProfile"
      v-model="selected_period_id"
      :items="periodList"
      item-value="id"
      item-text="label"
      dense
      hide-details
      :label="$getVisibleNames('mesh.period', false, 'Período')"
      class="mt-4 mb-2"
    ></v-select>
    <v-select
      v-model="selected_shift_id"
      :items="shifts"
      item-value="id"
      item-text="name"
      dense
      hide-details
      :label="$getVisibleNames('mesh.shift', false, 'Jornada')"
      class="mt-4 mb-2"
    ></v-select>
    <b-button
      v-if="allows_crud"
      size="sm"
      variant="primary"
      @click="createSection"
      v-can="'teaching.add_section'"
      >Agregar
      {{ $getVisibleNames("teaching.section", false, "Sección") }}</b-button
    >
    <b-table
      :items="filteredSections"
      :fields="fields"
      striped
      responsive="sm"
      small
      head-variant="light"
      :caption-top="true"
      bordered
    >
      <template v-slot:table-caption>{{
        $getVisibleNames("teaching.section", true, "Secciones")
      }}</template>
      <template v-slot:head(show)="data">
        <b-form-checkbox
          @change="selectAllSections"
          :checked="true"
          :title="data.label"
        ></b-form-checkbox>
      </template>
      <template v-slot:cell(show)="row">
        <b-form-checkbox
          :checked="row.item.show_in_calendar"
          @change="emitSections(row.item)"
          :ref="`checkbox-${row.item.id}`"
        ></b-form-checkbox>
      </template>
      <template v-slot:cell(matter)="row">
        <b-link
          :to="{
            name: 'MatterProgramView',
            params: { matter_id: row.item.matter },
          }"
          >{{ getMatterName(row.item.matter) }}</b-link
        >
      </template>
      <template v-slot:cell(name)="row">
        <b-link @click="launchModalUpdateSection(row.item)">{{
          row.item.name
        }}</b-link>
      </template>
      <template v-slot:cell(show_details)="row">
        <b-form-input
          id="input-color"
          v-model="row.item.color"
          size="sm"
          type="color"
          @change="updateColor(row.item)"
          :disabled="!allows_crud"
        ></b-form-input>
      </template>
    </b-table>
    <template v-if="matterWithoutSections.length > 0">
      <b-table
        :items="matterWithoutSections"
        :fields="[{ key: 'name', label: 'Nombre' }, 'Acciones']"
        striped
        responsive="sm"
        small
        head-variant="light"
        :caption-top="true"
        bordered
      >
        <template v-slot:table-caption
          >{{
            $getVisibleNames("mesh.egressprofilematter", true, "Asignaturas")
          }}
          Sin
          {{
            $getVisibleNames("teaching.section", true, "Secciones")
          }}</template
        >
        <template v-slot:cell(name)="row">
          <b-link
            :to="{
              name: 'MatterProgramView',
              params: { matter_id: row.item.id },
            }"
            >{{ getMatterName(row.item.id) }}</b-link
          >
        </template>
        <template v-slot:cell(Acciones)="row" v-if="allows_crud">
          <b-button
            v-can="'teaching.add_section'"
            @click="createSectionForMatter(row.item)"
            size="sm"
            >Crear
            {{
              $getVisibleNames("teaching.section", false, "Sección")
            }}</b-button
          >
        </template>
      </b-table>
    </template>
    <!-- Modals -->
    <b-modal
      :title="'Crear ' + $getVisibleNames('teaching.section', false, 'Sección')"
      hide-footer
      size="xl"
      id="modal-create-section"
    >
      <SectionForm
        :egress_profile_id="selected_egress_profile_id"
        :allows_crud="allows_crud"
        :period_id="selected_period_id"
        :shift_id="selected_shift_id"
        :campus_id="selected_campus_id"
        @created="slotCreatedSection"
        @updated="slotCreatedSection"
      ></SectionForm>
    </b-modal>
    <b-modal
      :title="'Crear ' + $getVisibleNames('teaching.section', false, 'Sección')"
      hide-footer
      size="xl"
      id="modal-create-section-for-matter"
    >
      <SectionForm
        :egress_profile_id="selected_egress_profile_id"
        :matter_id="selected_matter_id"
        :period_id="selected_period_id"
        :shift_id="selected_shift_id"
        :campus_id="selected_campus_id"
        :allows_crud="allows_crud"
        :edit_matter="false"
        :edit_level="false"
        :edit_period="false"
        :edit_shift="false"
        :edit_campus="false"
        @created="slotCreatedSection"
      ></SectionForm>
    </b-modal>
    <b-modal
      :title="
        'Modificar ' + $getVisibleNames('teaching.section', false, 'Sección')
      "
      hide-footer
      size="xl"
      id="modal-update-section"
    >
      <SectionForm
        :Section="selected_section"
        :egress_profile_id="selected_egress_profile_id"
        :allows_crud="allows_crud"
        @updated="slotUpdatedSection"
        @deleted="slotUpdatedSection"
      ></SectionForm>
    </b-modal>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";

export default {
  name: "MatterList",
  components: {
    SectionForm: () => import("./SectionForm"),
  },
  props: {
    career_id: {
      type: Number,
    },
    level: {
      type: Number,
    },
    period_id: {
      type: Number,
    },
    campus_id: {
      type: Number,
    },
    shift_id: {
      type: Number,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected_career_id: this.career_id,
      selected_level: this.level,
      selected_egress_profile_id: null,
      selected_period_id: this.period_id,
      horizontal_layout: false,
      sections: [],
      levels: [],
      selected_shift_id: this.shift_id,
      selected_campus_id: this.campus_id,
      selected_matter_id: null,
      selected_section: null,
    };
  },
  computed: {
    ...mapGetters({
      careers: names.CAREERS,
      campuses: names.CAMPUSES,
      egressProfiles: names.EGRESS_PROFILES,
      cycles: names.CYCLES,
      faculties: names.FACULTIES,
      matters: names.MATTERS,
      periods: names.PERIODS,
      shifts: names.SHIFTS,
    }),
    fields() {
      return [
        { key: "show", label: "Mostrar" },
        {
          key: "matter",
          label: this.$getVisibleNames(
            "mesh.egressprofilematter",
            false,
            "Asignatura"
          ),
          sortable: true,
        },
        { key: "name", label: "Nombre", sortable: true },
        { key: "show_details", label: "Color" },
      ];
    },
    careerList() {
      return this.careers;
    },
    career() {
      return this.careers.find((x) => x.id == this.selected_career_id);
    },
    egressProfile() {
      return this.egressProfiles.find(
        (x) => x.id == this.selected_egress_profile_id
      );
    },
    egressProfileList() {
      let list = [];
      if (this.career) {
        this.career.egress_profiles.forEach((element) => {
          const instance = this.egressProfiles.find((x) => x.id == element);
          if (instance) list.push(instance);
          else this.$store.dispatch(names.FETCH_EGRESS_PROFILE, element);
        });
      }
      return list;
    },
    filteredCampuses() {
      if (!this.egressProfile) return this.campuses;
      let list = [];
      this.egressProfile.campuses.forEach((campus_id) => {
        const instance = this.campuses.find((x) => x.id == campus_id);
        if (instance) list.push(instance);
      });
      return list;
    },
    matterList() {
      let list = [];
      if (!this.selected_egress_profile_id) return list;
      list = this.matters.filter(
        (x) => x.egress_profile == this.selected_egress_profile_id
      );
      if (this.selected_level)
        list = list.filter((x) => x.plan_level == this.selected_level);
      return list;
    },
    matterWithoutSections() {
      let list = this.matterList.filter((x) => x.sections.length == 0);
      let tmp_list = this.matterList.filter((x) => x.sections.length > 0);
      let flag = false;
      tmp_list.forEach((matter) => {
        matter.sections.forEach((section_id) => {
          if (this.filteredSections.find((x) => x.id == section_id))
            flag = true;
        });
        if (!flag) list.push(matter);
      });
      return list;
    },
    period() {
      if (!this.selected_period_id) return null;
      return this.periods.find((x) => x.id == this.selected_period_id);
    },
    periodList() {
      let list = [];
      this.periods.forEach((period) => {
        let tmp = period;
        tmp.label =
          period.name + " De: " + period.start_date + " al " + period.end_date;
        list.push(tmp);
      });
      return list;
    },
    filteredSections() {
      let list = this.sections;
      if (this.selected_shift_id)
        list = list.filter((x) => x.shift == this.selected_shift_id);
      if (this.selected_campus_id)
        list = list.filter((x) => x.campus == this.selected_campus_id);
      if (this.selected_level)
        list = list.filter((x) => x.plan_level == this.selected_level);
      if (this.selected_period_id)
        list = list.filter((x) => x.period == this.selected_period_id);
      return list;
    },
    selectedSections() {
      return this.filteredSections.filter((x) => x.show_in_calendar);
    },
  },
  methods: {
    emitSections(section) {
      const index = this.sections.findIndex((x) => x.id == section.id);
      if (index != -1) {
        const flag = this.sections[index].show_in_calendar;
        this.sections[index].show_in_calendar = !flag;
      }
      this.emitSelectedSections();
    },
    selectAllSections(flag) {
      this.sections.forEach((element) => {
        element.show_in_calendar = flag;
        let checkbox = this.$refs[`checkbox-${element.id}`];
        if (checkbox) {
          try {
            checkbox.checked = flag;
          } catch {
            () => {};
          }
        }
      });

      this.emitSelectedSections();
    },
    getMatterName(matter_id) {
      const matter = this.matters.find((x) => x.id == matter_id);
      return matter ? matter.name : "";
    },
    updateColor(section) {
      this.$store
        .dispatch(names.PATCH_SECTION, {
          section_id: section.id,
          item: { color: section.color },
        })
        .then(() => {
          this.emitSelectedSections();
        });
    },
    emitSelectedSections() {
      let sections_id = [];
      this.filteredSections.forEach((element) => {
        if (element.show_in_calendar) sections_id.push(element.id);
      });
      this.$emit("update_section_list", sections_id);
    },
    createSection() {
      this.$bvModal.show("modal-create-section");
    },
    createSectionForMatter(matter) {
      this.selected_matter_id = matter.id;
      this.$bvModal.show("modal-create-section-for-matter");
    },
    slotCreatedSection(section) {
      if (!this.filteredSections.find((x) => x.id == section.id)) {
        this.loadSections();
      }
      this.$bvModal.hide("modal-create-section-for-matter");
      this.$bvModal.hide("modal-create-section");
      this.selected_matter_id = null;
    },
    launchModalUpdateSection(section) {
      this.selected_section = section;
      this.$bvModal.show("modal-update-section");
    },
    slotUpdatedSection() {
      this.loadSections();
      this.$bvModal.hide("modal-update-section");
    },
    loadSections() {
      if (this.selected_egress_profile_id) {
        this.$store
          .dispatch(names.FETCH_EGRESS_PROFILE, this.selected_egress_profile_id)
          .then((ep) => {
            this.levels = [];
            for (let level = 1; level <= ep.semester_amount; level++) {
              this.levels.push(level);
            }
          });
        this.sections = [];
        let counter = 0;
        this.$store
          .dispatch(names.FETCH_MATTERS, this.selected_egress_profile_id)
          .then((matters) => {
            let tmp_list = [];
            matters.forEach((matter) => {
              tmp_list = tmp_list.concat(matter.sections);
            });
            tmp_list.forEach((section_id) => {
              counter += 1;
              this.$store
                .dispatch(names.FETCH_SECTION, section_id)
                .then((response) => {
                  if (response.show_in_calendar === undefined)
                    response.show_in_calendar = true;
                  this.sections.push(response);
                  counter -= 1;
                  if (counter == 0) {
                    this.emitSelectedSections();
                  }
                });
            });
          });
      }
    },
  },
  created() {
    this.$store.dispatch(names.FETCH_PERIODS);
    this.$store.dispatch(names.FETCH_SHIFTS);
    if (this.career && this.career.egress_profiles.length > 0)
      this.selected_egress_profile_id = this.career.egress_profiles[0];
  },
  watch: {
    career() {
      if (this.career.egress_profiles.length > 0)
        this.selected_egress_profile_id = this.career.egress_profiles[0];
    },
    selected_egress_profile_id() {
      this.loadSections();
      this.$emit("changed_egress_profile", this.selected_egress_profile_id);
    },
    selected_shift_id() {
      this.emitSelectedSections();
    },
    selected_campus_id() {
      this.emitSelectedSections();
    },
    selected_level() {
      this.emitSelectedSections();
    },
    matterList() {
      this.matterList.forEach((matter) => {
        matter.sections.forEach((section_id) => {
          this.$store.dispatch(names.FETCH_SECTION, section_id);
        });
      });
    },
    filteredCampuses() {
      if (this.filteredCampuses.length > 0 && !this.selected_campus_id) {
        this.selected_campus_id = this.filteredCampuses[0].id;
      }
    },
  },
};
</script>

<style scoped>
.card-body {
  padding: 0px;
}
</style>